<template>
  <div>
    <big-title>
      Actualiza tu <span>perfil</span>.
    </big-title>

    <!-- SECCIONES -->

    <vx-card v-if="isMounted && !failed">
      <div slot="no-body" class="tabs-container px-6 pt-6">
        
        <vs-tabs v-model="activeTab" class="tab-action-btn-fill-container">
          <vs-tab :label="group.title" icon-pack="feather" :icon="group.icon" v-for="(group, i) in sectionGroups" :key="i">
            <div class="tab-text">              
              <vx-card v-for="section in group.sections" :key="section.data.id" :title="section.data.public_name" class="mb-5">
                <p class="mb-4" v-if="section.data.public_description != null">{{section.data.public_description}}</p>

                <div class="vx-row">
                  <template v-for="f in section.data.fields">

                    <!-- Campos automaticos -->
                    <form-field 
                      :ref="group.slug"
                      v-if="isAutoFormField(f)"
                      :key="f.id"
                      :f="f"
                      :dataContainer="getContainerForField(section, f)"
                      :keepDisabled = "true"
                      :collectionsRoot="collections"
                      :onChangeReceptor="onFieldChange"
                      :countriesList="collections.countries"
                      :evaluator="evaluateCondition" />

                    <!-- Campos especiales -->
                    <personal-profile-tins-control
                      :key="f.id"
                      :f="f"
                      ref="personal_profile_tins_component"                     
                      v-else-if="f.fname == 'personal_tins' && should_fill_person_type_2_tax_profile_1" 
                      :countries="collections.countries"
                      :personal_profile_id = 'personal.id' />

                     <business-profile-tins-control
                      :key="f.id"
                      :f="f"
                      ref="business_profile_tins_component"                      
                      v-else-if="f.fname == 'business_tins' && should_fill_person_type_3_or_4" 
                      :countries="collections.countries"
                      :business_profile_id = 'business.id' />

                     <business-stakeholders-control
                      :key="f.id"
                      :f="f"
                      ref="business_stakeholders_component"                      
                      v-else-if="f.fname == 'business_stakeholders'" 
                      :countries="collections.countries"
                      :business_profile_id = 'business.id' />
                  </template>
                </div>

                <template v-if="section.data.documentFields.length > 0">
                  <vs-divider></vs-divider>
                  <h6 class="mb-3">Documentos</h6>
                  <div class="vx-row">
                    <document-upload
                    v-for="df in section.data.documentFields"
                    :key="df.id"
                    :target_id="UserId"
                    :f="df.formField"
                    :doc="df.documentSet"
                    :disabled = "true"
                    :evaluator="evaluateCondition"
                    />
                  </div>
                </template>  
              </vx-card>
              
              <!-- Save Button -->
              <!-- <div class="vx-row">
                <div class="vx-col w-full">
                  <div class="flex flex-wrap items-center justify-end">
                    <vs-button class="ml-auto mt-2" @click="saveTabChanges(group.slug)">Guardar cambios</vs-button>                    
                  </div>
                </div>
              </div> -->

            </div>
          </vs-tab>

        </vs-tabs>

      </div>
    </vx-card>

    <load-error v-if="failed" />

  </div>
</template>

<script>
import { mapState } from 'vuex';
import inputHelper from '../../../components/mixins/inputHelper';
import dateHelper from '../../../components/mixins/dateHelper';
import formHelper from '../../../components/mixins/formHelper';
import FormField from '@components/forms/FormField.vue'
import PersonalProfileTinsControl from '@components/supplier/profile/PersonalProfileTinsControl.vue'
import BusinessProfileTinsControl from '@components/supplier/profile/BusinessProfileTinsControl.vue'
import BusinessStakeholdersControl from '@components/applicant/BusinessStakeholdersControl.vue'

const pfaeTabsDefs = [
  { slug: "general", title: "Datos generales",  icon: "icon-briefcase", sections:[61,62] },
  { slug: "address", title: "Domicilio", icon: "icon-map-pin", sections:[32] },  
  //{ slug: "bank", title: "Banco", icon: "icon-credit-card", sections:[35] }
];

const pmTabsDefs = [
  { slug: "general", title: "Datos Generales", icon: "icon-briefcase", sections:[87] },
  { slug: "address", title: "Domicilio", icon: "icon-map-pin", sections:[88] },  
  //{ slug: "bank", title: "Banco", icon: "icon-credit-card", sections:[35] },
  { slug: "contact", title: "Representante legal", icon: "icon-credit-card", sections:[89] },
  //{ slug: "corporate", title: "Estructura corporativa", icon: "icon-phone", sections:[75] },
  //{ slug: "stakeholders", title: "Estructura accionaria", icon: "icon-bookmark", sections:[76] },
];

const sectionsToContainers = [
  { id: 61, containers: [{db:'personal_profiles', path:'user.personal'},{db:'users', path:'user'}] },
  { id: 62, containers: [
    {db:'personal_profiles_tins', path:'user.personal.tins', arrayDefs: {}}
    ,{db:'personal_profiles', path:'user.personal'}
    ,{db:'phones', path:'user.personal.phone'}
  ] },
  { id: 32, containers: [{db:'addresses', path:'user.personal.address'}] },
  { id: 35, containers: [{db:'bank_accounts', path:'user.fiscal.bank_accounts', arrayDefs: {getFirst: true, onNull: 'newBankAccount'}}] },
  { id: 87, containers: [
    {db:'business_profiles', path:'user.business'},
    {db:'business_profile_tins', path:'user.business.tins', arrayDefs:{}}
  ]},
  { id: 88, containers: [
    {db:'business_profiles', path:'user.business'},
    {db:'addresses', path:'user.business.address'},
    {db:'phones', path:'user.business.phone', arrayDefs:{}}
  ]},
  { id: 75, containers: [{db:'business_profile_corporate_structures', path:'user.business.corporate_structure'}]},
  { id: 76, containers: [{db:'business_profile_stakeholders', path:'user.business.stakeholders', arrayDefs:{}}]},
  { id: 89, containers: [{db:'personal_profiles', path:'user.business.personal'}]},
];

const requiredObjects = [
  'user.personal.address',
  'user.personal.tins',
  'user.personal.phone',
  'user.personal.bankAccounts',
  'user.business.personal',
  'user.business.personal.phone',
  'user.business.address',
  'user.business.tins',
  'user.business.phone',
  'user.business.bankAccounts',
  'user.business.stakeholders'
];

const arrayPaths = ['user.personal.bank_accounts','user.personal.tins','user.business.bank_accounts','user.business.tins','user.business.stakeholders','user.fiscal.bank_accounts'];

const addressMinDefault = {
  id: null,
  country_id: null,
  city_id: null,
};
const fiscalAddressMinDefault = {
  id: null,
  country_id: null,
  city_id: null,
  address_equals_fiscal_address: null
}

export default {
  mixins: [formHelper, inputHelper, dateHelper],
  data(){
    return {
      moralTypeValue: 0,
      pfaeTypeValue: 1,
      flow_id_pfae: 7,
      flow_id_pm: 8,
      loading: false,
      isMounted: false,
      isRefreshing: false,
      errorMssg: '',
      successMssg: '',
      failed: false,
      flow: {},
      allSections: [],
      sectionGroups: [],
      documentsStates: [],

      tabs: [],

      clientType: null,
      isMoral: false,
      isPfae: false,
      activeTab: 0,

      missing: {
        enterprise: '',
        acredit: '',
        contact: '',
        residence: '',
        bank: ''
      },

      collections: {
        cities: [],
        fiscal_cities: [],
        states: [],
        business_activities: [],
        countries: [],

        states_application_address: [],
        cities_application_address: [],
        neighborhoods_application_address: [],
        states_business_application_address: [],
        cities_business_application_address: [],
        neighborhoods_business_application_address: [],
      },

      supplierTins: [],

      // objeto principal
      base: {
        user: {
          personal: {
            address: {},
            fiscal_address: {},
            bank_accounts: [],
          },
          business: {
            address: {},
            fiscal_address: {},
            bank_accounts: [],
            personal: {
              address: {},
            },
          }
        }
      },
      // fin objeto principal

      // auxiliares para evaluacion de condiciones
      mexicoCountryId: 700,

      // objetos para actualizaciones
      basePayload: {},
      validatedFields: [],

    }
  },
  components: {
    FormField,
    PersonalProfileTinsControl,
    BusinessProfileTinsControl,
    BusinessStakeholdersControl
  },
  computed: {
    ...mapState("auth", ["user"]),    
    personal(){
      return this.base.user.personal;
    },
    business(){
      return this.base.user.business;
    },
    personalBankAccount(){
      return this.base.user.personal.bank_accounts[0];
    },
    businessBankAccount(){
      return this.base.user.business.bank_accounts[0];
    },

    /* show_only_if getters */
    should_fill_personal_fiscal_address(){
      return !this.base.user.personal.fiscal_address.address_equals_fiscal_address;
    },
    should_fill_business_fiscal_address(){
      if(this.business.fiscal_address.address_equals_fiscal_address != null){
        return !this.business.fiscal_address.address_equals_fiscal_address;
      }
      else{ return false; }
    },
    should_fill_business_mexican_address(){
      return this.business.address.country_id == this.mexicoCountryId;
    },
    should_fill_business_foreign_address(){
      return this.business.address.country_id != this.mexicoCountryId;
    },
    should_fill_business_mexican_fiscal_address(){
      return this.should_fill_business_fiscal_address 
      && this.business.fiscal_address.country_id == this.mexicoCountryId;
    },
    should_fill_business_foreign_fiscal_address(){
      return this.should_fill_business_fiscal_address 
      && this.business.fiscal_address.country_id != this.mexicoCountryId;
    },
    should_fill_pm_rfc(){
      return this.business.nationality == this.mexicoCountryId;
    },
    should_fill_business_tins(){
      return this.business.nationality != this.mexicoCountryId;
    },
    should_fill_person_type_0_or_3(){
      return this.base.user.person_type == 0 || this.base.user.person_type == 3;
    },
    should_fill_person_type_0_or_4(){
      return this.base.user.person_type == 0 || this.base.user.person_type == 4;
    },
    should_fill_person_type_3_or_4(){
      return this.base.user.person_type == 3 || this.base.user.person_type == 4;
    },
    should_fill_person_type_1(){
      return this.base.user.person_type == 1;
    },
    should_fill_person_type_3(){
      return this.base.user.person_type == 3;
    },
    should_fill_person_type_1_tax_profile_1(){
      return this.base.user.person_type == 1 
      && this.personal.tax_profile == 1;
    },
    should_fill_person_type_2_tax_profile_1(){
      return this.base.user.person_type == 2
      && this.personal.tax_profile == 1;
    },
    should_fill_owner_rfc_curp_fiscal_rec(){
      return this.businessOwner.address.country_id == this.mexicoCountryId;
    },
    should_fill_owner_tins(){
      return this.businessOwner.address.country_id != this.mexicoCountryId;
    },
    should_fill_personal_curp(){
      return this.personal.citizenship == this.mexicoCountryId;
    },
    should_fill_owner_curp(){
      return this.businessOwner.address.country_id == this.mexicoCountryId;
    },    
    power_proof_is_required(){
      return this.business.power_proof_required;
    },
    should_fill_pm_foreign_bank_info(){
      return this.business.nationality != this.mexicoCountryId && !this.businessBankAccount.pm_bank_is_in_mexico;
    },
    should_fill_pm_clabe(){
      return (this.business.nationality == this.mexicoCountryId || this.businessBankAccount.pm_bank_is_in_mexico == true);
    },
    should_fill_pm_swift_bic(){
      return this.should_fill_pm_foreign_bank_info;
    },
    should_fill_pm_bank_address(){
      return this.should_fill_pm_foreign_bank_info;
    },
    should_fill_pm_account_number(){
      return this.should_fill_pm_foreign_bank_info && (this.businessBankAccount.iban == null || this.businessBankAccount.iban == "");
    },
    should_fill_pm_routing_number(){
      return this.should_fill_pm_foreign_bank_info && (this.businessBankAccount.iban == null || this.businessBankAccount.iban == "");
    },
    should_fill_pm_iban(){
      return this.should_fill_pm_foreign_bank_info;
    },
    should_fill_pm_bank_in_mexico(){
      return this.business.nationality != this.mexicoCountryId;
    },
    should_fill_stock_market_listed(){
      return this.business.corporate_structure.stock_market_listed == 0;
    },
    should_fill_stock_market_listed_or_social_capital_more_than_50(){
      return this.business.corporate_structure.stock_market_listed == 1 || this.business.corporate_structure.social_capital_more_than_50 == 1;
    },
    should_ask_for_pm_bank_confirmation(){
       return this.business.nationality != this.mexicoCountryId
    },

    /* disabled_if getters */
    should_fill_personal_bdate_gender(){
      return this.personal.citizenship == this.mexicoCountryId;
    },
    should_fill_owner_bdate_gender(){
      return this.businessOwner.citizenship == this.mexicoCountryId;
    },
  },
  async mounted() {
    this.loading = true;
    this.isMounted = false;
    await this.getCollections();
    await this.getApplicantData();
    await this.getSupplierStatusInfo();
    this.setPersonTypeInfo();
    await this.setCustomIntegrations();
    await this.getFormInfo();
    this.setSectionGroups();
    this.isMounted = true;
    this.loading = false;
  },
  watch: {
    loading : function(newVal, oldVal){
      if(newVal == true){
         this.$vs.loading();
      }
      else {
        this.$vs.loading.close();
      }
    }
  },
  methods: {
    async reloadFormInfo(){
      this.isRefreshing = true;
      await this.getApplicantData();
      await this.setCustomIntegrations();
      this.reloadSectionGroups();
      this.isRefreshing = false;
    },
    familyGroupIconUrl (img) {
      return require(`@assets/images/technologies/${img}`);
    },
    async saveTabChanges(gslug){
      // console.log("saving => " + gslug);
      let result = await this.runValidations(gslug);
      if(!result){
       this.missingFieldsNotif();
      }
      else {
        this.loading = true;

        //guardar solo la informacion que se ha modificado
        this.basePayload = this.collectFormData(gslug);
        if(this.objectIsEmpty(this.basePayload) && gslug != "knowledge"){
          //this.notFieldsToUpdate();
          this.saveSuccessNotif();
          this.loading = false;
          return;
        }

        this.basePayload.id = this.base.id;
        if(gslug == 'knowledge'){
          // recoleccion manual de valores
          this.collectKnowledgeData();
        }
        
        try {
          // ejecutar guardado
          let response = await axios.put(`/api/v1/applicant/${this.ApplicantId}/deepUpdate`, this.basePayload);
          // console.log(response.data);
          // solicitar la informacion actualizada del modelo
          await this.reloadFormInfo();

          // actualizar la informacion general del usuario en caso de ser necesario
            if(gslug == "general"){
              await this.onUserInfoUpdated();
            }

          this.saveSuccessNotif();          
        }
        catch (error) {
          this.failedOperationNotif(null, null);          
        }
        
        this.loading = false;        
      }
    },
    async runValidations(gslug){
       // validar componentes tipo FormField
      let res1 = await this.validateFormFields(gslug);
      if(!res1){
        return false;
      }
    
      // validar inputs instanciados en la vista
      let res2 = await this.$validator.validateAll();
      if(!res2){
        return false;
      }
    
      // inspeccionar los campos para ejecutar validaciones especiales en campos tipo FormField
      let spValidation = await this.checkFormFieldsSpecialValidations(gslug);
      if(!spValidation){
        return false;
      }

      // inspeccionar los campos agregados directo en el componente para ejecutar validaciones especiales
      let group = this.sectionGroups.find(g => g.slug == gslug);
      let sp_failed = false;
      group.sections.forEach(s => {
        s.data.fields.filter(f => f.special_definition == 1).forEach(f => {
          // considerar los campos habilitados (visibles), con validaciones especiales
          if(this.fields[f.fname] && f.special_validation != null && f.special_validation.method != null) {
            let res = this[f.special_validation.method]();

            if(!res){
              this.errors.add({
                field: f.fname,
                msg: f.special_validation.error
              });
              this.missingFieldsNotif(f.field_name, f.special_validation.error, 10000);
              sp_failed = true;
            }
          }
        });
      });

      if(sp_failed){
        return false;
      }

      // retornar true si todas las validaciones fueron positivas
      return true;
    },
    collectFormData(gslug){
      let payload = {};
      let group = this.sectionGroups.find(g => g.slug == gslug);
      group.sections.forEach(section => {
        section.containers.forEach(cc => {
          let fields = this.validatedFields.filter(vf => vf.db == cc.db);
          if(fields.length < 1){
            return;
          }

          if(cc.path == "base"){
            fields.forEach(f => {
              payload[f.fname] = cc.container[f.fname];
            });
          }
          else {
            let obj = null;
            if(arrayPaths.includes(cc.path)){
              // se integran todos los objetos de la coleccion
              obj = this.nestedFieldValue(this.base, cc.path);
            }
            else {
              obj = {id: cc.container.id};
              fields.forEach(f => {
                obj[f.fname] = cc.container[f.fname];
              });
            }
            // ajuste para objetos tipo array
            this.setDeepObjectWithIds(payload, this.base, cc.path, obj);
          }          
        });
      });
      return payload;
    },
    async validateFormFields(refGroup){
      let allValid = true;
      this.validatedFields = [];
      let grefs = this.$refs[refGroup];
      for(let p in grefs){
        let r = await this.$refs[refGroup][p].checkForValidDirty();
        if(r.valid == false){
          allValid = false;
        }
        else if(r.dirty == true) {
          this.validatedFields.push(r);
        }
      }
      return allValid;
    },
    async checkFormFieldsSpecialValidations(refGroup){
      let spvalid = true;
      let grefs = this.$refs[refGroup];
      for(let p in grefs){
        let sp = this.$refs[refGroup][p].specialValidation();
        if(sp != null && sp in this){
          let res = this[sp.method]();
          let n = this.$refs[refGroup][p].fieldName;
          if(!res){
            spvalid = false;
            this.errors.add({
              field: n,
              msg: sp.error
            });
            this.missingFieldsNotif(n, sp.error, 10000);
          }
        }
      }
      
      return spvalid;
    },
    collectKnowledgeData(){
       // Herramientas del proveedor
        let t = [];
        this.supplierTools.forEach(f => {
          let item = {};
          item.type = f.type;
          item.other_tools = f.other_tools;
          item.checked = f.checked;
          t.push(item);
        });
        this.basePayload.tools = t;

         // Customer Types
        let c = [];
        this.supplierCustomerTypes.forEach(f => {
          let item = {};
          item.type = f.type;
          item.checked = f.checked;
          c.push(item);
        });
        this.basePayload.customer_types = c;
    },

    /* on change receptor */
    onFieldChange(method){
      if(method != null && method in this){
        // console.log("onFieldChange => " + method)
        this[method]();
      }
    },

    /* evaluator */
    evaluateCondition(condition){
      if(condition != null && condition in this){
        let ss = this[condition];
        // console.log("evaluateCondition => " + condition + ", " + ss);
        return ss;
      }      
    },

    setPersonTypeInfo(){
      this.clientType = this.base.user.person_type;
      this.isMoral = this.clientType == this.moralTypeValue;
      this.isPfae = this.clientType == this.pfaeTypeValue;
      this.tabs = this.isMoral ? pmTabsDefs : pfaeTabsDefs;
    },
    async setCustomIntegrations(){
        // direcciones personal profile
        let padd_sid = this.nestedFieldValue(this.base, 'user.personal.address.state_id');
        let pfadd_sid = this.nestedFieldValue(this.base, 'user.personal.fiscal_address.state_id');
        if(padd_sid != null && padd_sid != ""){ await this.reload_personal_cities() }
        if(pfadd_sid != null && pfadd_sid != ""){ await this.reload_personal_cities_fiscal()}

        let padd = this.nestedFieldValue(this.base, 'user.personal.address.id');
        let pfadd = this.nestedFieldValue(this.base, 'user.personal.fiscal_address.id');
        if(padd && pfadd && padd == pfadd){
          this.personal.fiscal_address = fiscalAddressMinDefault;
          this.personal.fiscal_address.address_equals_fiscal_address = true;
        }

        // direcciones business profile
        let badd_sid = this.nestedFieldValue(this.base, 'user.business.address.state_id');
        let bfadd_sid = this.nestedFieldValue(this.base, 'user.business.fiscal_address.state_id');        
        if(badd_sid != null && badd_sid != ""){ await this.reload_business_cities() }
        if(bfadd_sid != null && bfadd_sid != ""){ await this.reload_business_cities_fiscal()}

        let badd = this.nestedFieldValue(this.base, 'user.business.address.id');
        let bfadd = this.nestedFieldValue(this.base, 'user.business.fiscal_address.id');
        if(badd && bfadd && badd == bfadd){
          this.business.fiscal_address = fiscalAddressMinDefault;
          this.business.fiscal_address.address_equals_fiscal_address = true;
        }
    },
    setSectionGroups(){
      this.tabs.forEach(t => {
        let group = { title: t.title, icon: t.icon, slug: t.slug }; //, vModel: this.getModelForGroup(t.slug) };
        let sections = [];
        
        t.sections.forEach(ss => {
          let s = this.allSections.find(f => f.id == ss);
          if(s != null){
            let sb = { data: s, containers: this.getContainersForSection(s.id) };
            sections.push(sb);
          }
          else {
            this.warn("Section " + ss + " not found");
          }
        })
        group.sections = sections;
        this.sectionGroups.push(group);
      });
    },
    reloadSectionGroups(){
      this.sectionGroups.forEach(group => {
        group.sections.forEach(s => {
          s.containers = this.getContainersForSection(s.data.id);
        })
      });
    },
    /*getModelForGroup(slug){
      return this[slug];
    },*/
    getContainerForField(section, f){
      let c = section.containers.find(sc => sc.db == f.db_table);
      if(!c){
        this.warn(`Container not found for db [${f.fname}]: ${f.db_table}`);
        return null;
      }
      else {
        return c.container;
      }
    },
    getContainersForSection(section_id){
      let c = sectionsToContainers.find(f => f.id == section_id);
      if(!c){
        this.warn("Missing containers definition for section " + section_id);
        return null;
      }
      else {
        let sectionContainers = [];
        c.containers.forEach(cc => {
          let con = this.setContainerFromDef(cc);
          sectionContainers.push({db: cc.db, path: cc.path, container: con});
        })
        return sectionContainers;
      }
    },
    setContainerFromDef(c){
      let container = this.nestedFieldValue(this.base, c.path);
      if(Array.isArray(container)){
        let ac = null;
        if(c.arrayDefs){
          if(c.arrayDefs.getFirst == true){
            ac = container[0];
          }
          else if(c.arrayDefs.eval){
            // llamado en funciones de secciones
            ac = this[c.arrayDefs.eval](container);
          }
        }
        if(!ac){
          if(c.arrayDefs.onNull){ ac = this[c.arrayDefs.onNull](); }
          else { ac = {} }
          container.push(ac);
        }
        return ac;
      }
      else {
        if(container == null && c.arrayDefs) {
          let ac = null;
          if(c.arrayDefs.eval){
            // llamado en funciones de secciones
            ac = this[c.arrayDefs.eval]();
            return ac;
          }
          if(!ac){
            if(c.arrayDefs.onNull){ ac = this[c.arrayDefs.onNull](); }
            else { ac = {} }
            container = ac;
          }
        }
        return container;
      }
    },
    async getSupplierStatusInfo(){
      // TODO
    },
    async getApplicantData(){
      try{
        // ?filter[status]=3 // ejemplo de filtro
        let params = "with[]=" + requiredObjects.join("&with[]=");
        let response = await axios.get(`/api/v1/applicant/${this.ApplicantId}?${params}`);
        this.base = response.data;
      }
      catch(e){
        this.warn(e);
        this.failed = true;
      }
    },    
    async getFormInfo(){
      try{
        // let id = this.isMoral == true ? this.flow_id_pm : this.flow_id_pfae;
        let id = this.isMoral == true ? "40" : "34";
        let response = await axios.get("/api/v1/forms/getFormFields/"+id);

        // let response = await axios.get("/api/v1/forms/getFlowSectionFields/" + id);
        let url = this.ApiDomain + `/storagev3/documents/form/${id}`;
        let response2 = await axios.get(url);
        this.documentsStates = response2.data;

        // this.flow = response.data.flow;
        this.allSections = response.data.sections;        

        this.allSections.forEach(s => {
          if(this.tabs.filter(tt => tt.sections.includes(s.id)).length < 1){            
            return;
          }
          
          s.documentFields = [];
          s.fields.forEach(f => {
            this.formFieldSetter(f, this);

            // check for documents
            if(f.doc_id != null){
              let ff = {formField: f, documentSet: null };
              let ss = this.documentsStates.find(sid => sid.id == s.id);
              if(ss && ss.documents){
                ff.documentSet = ss.documents.find(d => d.id == f.doc_id);
              }
              s.documentFields.push(ff);
            }
          });
        });
      }
      catch(e){
        this.warn(e);
        this.failed = true;
      }
    },
    async getCollections(){
      try {
        let response1 = await axios.get('/api/register/countries');
        this.collections.countries = response1.data;

        let response2 = await axios.get('/api/register/states');
        this.collections.states = response2.data;

        let response3 = await axios.get('/api/v1/forms/getBusinessActivities');
        this.collections.business_activities = response3.data;

      } catch (e) {
        this.warn(e);
        this.failed = true;
      }
    },

    /* on_change methods */
    async reload_personal_cities(){
      try {
        let sid = this.personal.address.state_id;
        let res = await axios.get(`/api/register/cities/${sid}`);
        this.collections.cities = res.data;
        if(this.isMounted && !this.isRefreshing) this.personal.address.city_id = null;
      } catch (e) {
        this.warn(e);
      }
    },
    async reload_personal_cities_fiscal(){
      try {
        let sid = this.personal.fiscal_address.state_id;
        let res = await axios.get(`/api/register/cities/${sid}`);
        this.collections.fiscal_cities = res.data;
        if(this.isMounted && !this.isRefreshing) this.personal.fiscal_address.city_id = null;
      } catch (e) {
        this.warn(e);
      }
    },
    async reload_business_cities(){
      try {
        let sid = this.business.address.state_id;
        let res = await axios.get(`/api/register/cities/${sid}`);
        this.collections.cities = res.data;
        if(this.isMounted && !this.isRefreshing) this.business.address.city_id = null;
      } catch (e) {
        this.warn(e);
      }
    },
    async reload_business_cities_fiscal(){
      try {
        let sid = this.business.fiscal_address.state_id;
        let res = await axios.get(`/api/register/cities/${sid}`);
        this.collections.fiscal_cities = res.data;
        if(this.isMounted && !this.isRefreshing) this.business.fiscal_address.city_id = null;
      } catch (e) {
        this.warn(e);
      }
    },
    personal_curp_conditions_changed(){
      if(!c){
        return;
      }
      let c = this.personal.curp;
      if(c.length >= 10){
        let yy = c.slice(4,6);
        let mm = c.slice(6,8);
        let dd = c.slice(8,10);
        
        let date = this.getDateFromInputs(yy, mm, dd);
        if(date === false){
          this.personal.birth_date = null;
        }
        else{
          this.personal.birth_date = date;
        }
      }
      else{
        this.personal.birth_date = null;
      }

      if(c.length >= 11){
        let g = c.slice(10, 11);
        if(g == "H"){
          this.personal.gender = 1;
        }
        else if(g == "M"){
          this.personal.gender = 2;
        }
        else{
          this.personal.gender = null;
        }
      }
      else{
        this.personal.gender = null;
      }

      if(c.length >= 13){
        let code = c.slice(11, 13);
        let state = this.collections.states.find(f => f.renapo_code == code)
        if(state != null){
          this.personal.birth_state = state.id;
        }
      }
      else{
        this.personal.birth_state = null;
      }
    },

    /* funciones de secciones */
    filterGeneralContact(collection){
      return collection.find(f => f.type == 1);
    },
    filterCommercialContact(collection){
      return collection.find(f => f.type == 2);
    },
    filterOperationsContact(collection){
      return collection.find(f => f.type == 3);
    },
    newGeneralContact(){
      return {type: 1};
    },
    newCommercialContact(){
      return {type: 2};
    },
    newOperationsContact(){
      return {type: 3};
    },
    newBankAccount(){
      return {id: null};
    },

    /* validaciones especiales */
    validate_user_birthdate(){
      let years = this.calculateAgeFromDate(this.personal.birth_date);
      return years >= 18;
    },
    validate_personal_profile_tins(){
      if(this.$refs.personal_profile_tins_component != null){
        return this.$refs.personal_profile_tins_component.validate();
      }
      else {
        return true;
      }
    },
    validate_business_profile_tins(){
      if(this.$refs.business_profile_tins_component != null){
        return this.$refs.business_profile_tins_component.validate();
      }
      else {
        return true;
      }
    },
    validate_business_stakeholders(){
      if(this.$refs.business_stakeholders_component != null){
        return this.$refs.business_stakeholders_component.validate();
      }
      else {
        return true;
      }
    },
    verify_zip_code_application_address() {
      this.verifyZipCode(this.personal.address, {
        zipCodeVariable: 'zip_code_application_address',
        statesArray: 'states_application_address',
        citiesArray: 'cities_application_address',
        neighborhoodsArray: 'neighborhoods_application_address'
      });
    },
    set_zip_code_values_application_address() {
      this.setZipCodeAddressValues(this.personal.address_id, this.personal.address, {
        zipCodeVariable: 'zip_code_application_address',
        statesArray: 'states_application_address',
        citiesArray: 'cities_application_address',
        neighborhoodsArray: 'neighborhoods_application_address'
      });
    },
    
    verify_zip_code_business_application_address() {
      this.verifyZipCode(this.business.address, {
        zipCodeVariable: 'zip_code_business_application_address',
        statesArray: 'states_business_application_address',
        citiesArray: 'cities_business_application_address',
        neighborhoodsArray: 'neighborhoods_business_application_address'
      });
    },
    set_zip_code_values_business_application_address() {
      this.setZipCodeAddressValues(this.business.address_id, this.business.address, {
        zipCodeVariable: 'zip_code_business_application_address',
        statesArray: 'states_business_application_address',
        citiesArray: 'cities_business_application_address',
        neighborhoodsArray: 'neighborhoods_business_application_address'
      });
    }
  }
}
</script>
<template>
  <div class="vx-col w-full mb-5" >
    <h6>{{f.field_name}} </h6>
     <vx-tooltip 
      :title="f.field_name"
      color="primary"
      style="float:right;cursor:pointer;margin-top:-8px"
      :text="f.indication">
      <vs-chip color="primary">?</vs-chip>
    </vx-tooltip>
    <vs-button color="primary" type="border" icon-pack="feather" class="mb-4 mt-3"  @click="addStakeholder()">Agregar accionista</vs-button>
        
      <vs-table stripe :data="stakeholders" noDataText="No se han generado registros">
        <template slot="thead">
          <vs-th class="bold">Tipo de persona</vs-th>
          <vs-th class="bold">Nombre completo</vs-th>
          <vs-th class="bold">Número de identificación físcal</vs-th>
          <vs-th class="bold">Fecha de nacimiento/constitución</vs-th>
          <vs-th class="bold">Nacionalidad</vs-th>
          <vs-th class="bold">Porcentaje</vs-th>
          <vs-th class="bold">ACCIONES</vs-th>
        </template>

        <template slot-scope="{data}">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data">

            <vs-td :data="data[indextr].person_type">
              {{ data[indextr].person_type }}
            </vs-td>

            <vs-td :data="data[indextr].name">
              {{ data[indextr].name }}
            </vs-td>

            <vs-td :data="data[indextr].rfc">
              {{ data[indextr].rfc }}
            </vs-td>

            <vs-td :data="data[indextr].birth_date">
              {{ data[indextr].birth_date }}
            </vs-td>

            <vs-td :data="data[indextr].citizenship">
              {{ data[indextr].citizenship }}
            </vs-td>

            <vs-td :data="data[indextr].percentage">
              {{ data[indextr].percentage }} %
            </vs-td>

            <vs-td :data="data[indextr].id">
              <feather-icon icon="TrashIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current cursor-pointer" class="ml-6" @click.stop="removeItemRequest(data[indextr])" />
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>

      <vs-popup
        title="Agregar accionista"
        :active.sync="showFormPrompt">
        <div class="con-exemple-prompt">
          <vs-alert v-if="onError" class="h-full mb-5" color="danger">
            <span>{{ errorMssg }}</span>
          </vs-alert>
          <vs-alert v-if="onSuccess" class="h-full mb-5" color="success">
            <span>{{ successMssg }}</span>
          </vs-alert>

          <p class="mb-5 bold">{{formPromptIndication}}</p>

          <vs-select class="w-full mb-5" 
            label="Tipo de persona del accionista" 
            v-model.lazy="person_type"
            name="person_type"
            v-validate="'required'"
            :danger="hasError('person_type')"
            :danger-text="errorText('person_type')"
            :success="isSuccess('person_type')"
            placeholder="Seleccione una opción" >
            <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in personTypes" class="w-full" />
          </vs-select>

          <vs-input
            class="w-full mb-4" 
            label="Nombre completo"
            placeholder="Requerido"
            v-validate="'required'"
            data-vv-name="name"
            :danger="hasError('name')"
            :danger-text="errorText('name')"
            :success="isSuccess('name')"
            v-model.lazy="name" />  

            <label for="" class="vs-input--label">Fecha de nacimiento/constitución</label>
          <datepicker
            class="w-full mb-4" 
            label="Fecha de nacimiento/constitución"
            placeholder="Requerido"
            :language="es"
            v-validate="'required'"
            data-vv-name="birth_date"
            format="dd/MM/yyyy"
            initial-view="year"
            :danger="hasError('birth_date')"
            :danger-text="errorText('birth_date')"
            :success="isSuccess('birth_date')"
            v-model.lazy="birth_date" />

          <vs-input
            class="w-full mb-4" 
            label="Número de identificación físcal (NIF, RFC, Tax ID)"
            placeholder="Requerido"
            v-validate="'required'"
            data-vv-name="rfc"
            :danger="hasError('rfc')"
            :danger-text="errorText('rfc')"
            :success="isSuccess('rfc')"
            v-model.lazy="rfc" />  

          <vs-select class="w-full mb-5" 
            label="Nacionalidad" 
            v-model.lazy="citizenship"
            name="citizenship"
            v-validate="'required'"
            :danger="hasError('citizenship')"
            :danger-text="errorText('citizenship')"
            :success="isSuccess('citizenship')"
            placeholder="Seleccione una opción" >
            <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in getCountriesForSelect" class="w-full" />
          </vs-select>

        <vs-input
            class="w-full mb-4" 
            label="Porcentaje de participación"
            placeholder="Requerido"
            v-validate="'required|min:1|max:100'"
            data-vv-name="percentage"
            type="number"
            :danger="hasError('percentage')"
            :danger-text="errorText('percentage')"
            :success="isSuccess('percentage')"
            v-model.lazy="percentage" />

          <vs-button :disabled="!completeForm || $isLoading" @click="saveStakeholder" color="primary" class="mr-5 mt-2">Agregar</vs-button>
          <vs-button @click="closeFormPrompt" :disabled="$isLoading" type="flat" color="primary">Cerrar</vs-button>
            
        </div>
      </vs-popup>

    </div>

</template>

<script>
import inputHelper from "@mixins/inputHelper";
import { es } from "vuejs-datepicker/dist/locale";
import Datepicker from "vuejs-datepicker";
const indicationDef = "Ingresa los datos solicitados.";
const errorMssgDef = "Ha ocurrido un error al agregar al accionista, intente más tarde."
const successMssgDef = "El accionista ha sido agregado exitosamente"
const statePresenceVal = 0;
const cityPresenceVal = 1;

export default {
  name: 'business-stakeholders-control',
  mixins: [inputHelper],
  components: {
    Datepicker
  },
  props: {
    f: { type: Object, required: true },
    countries: { type: Array, required: true },
    business_profile_id: { type: [String, Number], required: true },
  },
  data(){
    return {
        es: es,
        person_type:null,
        name:null,
        rfc:null,
        birth_date:null,
        citizenship:null,
        percentage:1,

        getCountriesForSelect: [],
        stakeholders: [],

        personTypes: [
            {text: 'Persona física', value: 'persona_fisica'},
            {text: 'Persona moral', value: 'persona_moral'},
        ],

        showFormPrompt: false,
        selectedStakeholder: null,

        onError: false,
        onSuccess: false,
        errorMssg: errorMssgDef,
        successMssg: successMssgDef,
        formPromptIndication: indicationDef,
    }
  },
  mounted: async function(){
    let options = [];
    this.$props.countries.forEach(opt => {
      options.push({value: opt.id, text: opt.name}); //conversion a sintaxis de vs-select 
    })
    this.getCountriesForSelect = options;
    await this.loadSavedStakeholders();
  },
  computed: {
    completeForm(){
      return this.person_type != null && this.name != null && this.rfc != null && this.birth_date != null && this.citizenship != null && this.percentage > 0 && this.percentage <= 100;
    }
  },
  methods: {
    async loadSavedStakeholders(){
      try {
        let res = await axios.get(`/api/v1/business/${this.$props.business_profile_id}/getStakeholders`);
        let collection = res.data;
        this.stakeholders = [];

        collection.forEach(f => {
          let tt = { id: f.id, person_type: f.person_type,name: f.name, rfc: f.rfc, birth_date: f.birth_date, citizenship: f.citizenship, percentage: f.percentage};
          this.stakeholders.push(tt);
        });
      }
      catch (e) {
        this.warn(e);
      }      
    },
    removeItemRequest(stakeholder){
      this.selectedStakeholder = stakeholder;

      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Eliminar accionista',
        text: `¿Realmente desea eliminar el accionista?`,
        acceptText: "Eliminar",
        cancelText: 'Cancelar',
        accept: this.doRemoveItem
      });
    },
    async doRemoveItem(){
      this.showLoading(true);
      try {
        let payload = {stakeholder_id: this.selectedStakeholder.id};
        await axios.post(`/api/v1/business/${this.$props.business_profile_id}/removeStakeholder`, payload);
        await this.loadSavedStakeholders();
      }
      catch (error) {
        this.warn(error);
      }
      this.showLoading(false);
    },
    async saveStakeholder(){
      this.onSuccess = false;
      this.onError = false;
      
      this.showLoading(true);
      try {
        this.percentage = this.percentage / 100;
        let payload = { person_type: this.person_type, name: this.name, rfc: this.rfc,birthdate: this.birth_date, citizenship: this.citizenship, percentage: this.percentage};
        await axios.post(`/api/v1/business/${this.$props.business_profile_id}/insertStakeholder`, payload);
        this.onSuccess = true;
        await this.loadSavedStakeholders();
        this.closeFormPrompt();
      }
      catch (error) {
        this.warn(error);
        this.onError = true;
      }
      this.showLoading(false);
    },
    addStakeholder(){
      this.resetForm();
      this.showFormPrompt = true;
    },
    resetForm(){
      this.person_type = null
      this.name = null
      this.rfc = null
      this.birth_date = null
      this.citizenship = null
      this.percentage = 1
      this.city_id = null
      this.onError = null
      this.onSuccess = null      
      this.sent = null
    },
    closeFormPrompt() {
      this.showFormPrompt = false;
      this.resetForm();
    },
    hasError(val){
      return this.errors.has(val);
    },
    errorText(val){
      return this.errors.first(val);
    },
    isSuccess(val){
      let ff = this.fields[val];
      return (ff && ff.required && ff.valid);      
    },

    /* check validation */
    validate(){
      return this.stakeholders.length >= 1;
    }
  }
}
</script>